import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-100 bg-gradient-to-b from-gray-800 to-black text-white about"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-5">
          Your trusted partner in financial growth and stability.
          Offering personalized financial advice and innovative investment opportunities tailored to your needs.
          Secure your future with our comprehensive range of financial services, including loans, savings, and investment plans.
          Our expert team is dedicated to helping you achieve your financial goals with transparency and integrity.
          Join us and experience financial peace of mind with services designed to elevate your wealth and security.

        </p>

        <br />

        <p className="text-xl">
          I like to code matters from scratch and love the idea of bringing thoughts to life. Connect with me to get your project done. <br /> <br />
          I value minimalistic designs, thoughtful branding of the content, and customer relatable experience. Let’s discover together how we can make your project convert better!<br /> <br />
          I enjoy creating or redesigning a distinct identity for a product or service, get more traffic from search engine and social platforms.
        </p>
      </div>
    </div>
  );
};

export default About;
